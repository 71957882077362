<style>
  .blur-content {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
    cursor: not-allowed;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  .blur-content-text {
    position: absolute;
    text-align: center;
    text-shadow: 1px 1px black;
    //display: none;
    cursor: not-allowed;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .blur-content:hover + .blur-content-text {
    display: block;
  }
  .blur-content-text:hover {
    display: block;
  }
  .profile-link {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: .5px;
    color: #bfc8e2 !important;
  }
  .profile-server-link {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: .5px;
    color: #bfc8e2 !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
  .profile-link:hover {
    color: #a7b0c9 !important;
  }
  .profile-links {
    margin-left: 5rem;
    margin-top: 2rem;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    -o-user-select: none;
    user-select: none;
  }
  .profile-component-col {
    margin-top: 2rem;
  }
  .profile-link-active {
    color: white !important;
  }
  .mobile-menu {
    width: 100vw;
    height: 3rem;
    background-color: #545a6d;
    display: flex;
    overflow-x: scroll;
    margin-bottom: 1rem;
  }
  .mobile-container {
    height: 3rem;
    min-width: 4rem;

    text-align: center;

    padding-top: 0.75rem;
    padding-bottom: 2rem;

    cursor: pointer;
    margin-left: 1rem;
    margin-right: 1rem;
    color: white;
    white-space: nowrap;
  }
  .mobile-container.active {
    border-bottom: 3px solid var(--primary);
  }
  .mobile-server-container {
    display: inline-block;

    margin-left: 1rem;
    margin-right: 1rem;

    color: white;
    white-space: nowrap;
    text-align: center;

    padding-top: 0.75rem;
    padding-bottom: 2rem;
    cursor: pointer;
  }
  .mobile-server-container.active {
    border-bottom: 3px solid var(--primary);
  }
</style>
<script>
import { VclCode } from 'vue-content-loading';

import { HalfCircleSpinner } from 'epic-spinners'
import {AccountStatus} from '@/enums';
import CopyableText from "@/components/CopyableText";
import {mapGetters} from "vuex";

export default {
  props: ['cftools_id', 'privacyMode'],
	computed: {
		...mapGetters({
			getServers: 'getServers',
			getSettings: 'getSettings'
		}),
		// Used for display purposes
		serverStructureMap() {
			let servers = {};
			if(!this.structure || !this.structure.servers) return servers;
			this.structure.servers.forEach((server) => {
				servers[server.id] = server;
			});
			return servers;
		},
		serverMap() {
			let servers = {};
			this.getServers().forEach((server) => {
				if(server.id in this.serverStructureMap) {
					servers[server.id] = server;
					servers[server.id].dataset_available = this.serverStructureMap[server.id].dataset_available;
				}
			});
			return servers;
		},
		orderedServers() {
			let servers = [];
			let knownServers = [];
			if(this.dynamicServerOrder && this.dynamicServerOrder.length) {
				this.dynamicServerOrder.forEach((server) => {
					if(server.id in this.serverMap) {
						knownServers.push(server.id);
						servers.push(this.serverMap[server.id]);
					}
				});
			}

			this.getServers().forEach((server) => {
				if(!knownServers.includes(server.id) && server.id in this.serverMap) servers.push(this.serverMap[server.id]);
			});

			return servers;
		},
		dynamicServerOrder() {
			return this.serverOrder || this.$store.state.settings.servers;
		}
	},
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    // eslint-disable-next-line vue/no-unused-components
    VclCode,
    HalfCircleSpinner,
    // Desktop
    ProfileOverview: () => import('@/components/lazy/profile/desktop/ProfileOverview'),
    ProfileIdentities: () => import('@/components/lazy/profile/desktop/ProfileIdentities'),
    ProfileServer: () => import('@/components/lazy/profile/desktop/ProfileServer'),
    ProfileBans: () => import('@/components/lazy/profile/desktop/ProfileBans'),
	  ProfileIntelligence: () => import('@/components/lazy/profile/desktop/ProfileIntelligence'),
    ProfileActivities: () => import('@/components/lazy/profile/desktop/ProfileActivities'),
    ProfileServerLoading: () => import('@/components/lazy/profile/desktop/ProfileServerLoading'),
	  ProfileAudit: () => import('@/components/lazy/profile/desktop/ProfileAudit'),
    // Mobile
    MobileProfileOverview: () => import('@/components/lazy/profile/mobile/MobileProfileOverview'),
    MobileProfileIdentities: () => import('@/components/lazy/profile/mobile/MobileProfileIdentities'),
    MobileProfileBans: () => import('@/components/lazy/profile/desktop/ProfileBans'),
    MobileProfileServer: () => import('@/components/lazy/profile/desktop/ProfileServer'),
    MobileProfileActivities: () => import('@/components/lazy/profile/desktop/ProfileActivities'),
	  MobileProfileTraces: () => import('@/components/lazy/profile/components/traces/TracesMobile'),
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/structure`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.structure = data;
          this.options.structure = this.structure;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    changeTab(tab, options) {
      this.options = Object.assign({}, this.options, options);
      if(tab === 'ProfileServer' || tab === 'MobileProfileServer') {
        // Component name is the same, so switching does not properly work
        this.currentTab = 'ProfileServerLoading';
        setTimeout(() => {
          this.currentTab = tab;
        }, 1);
      } else this.currentTab = tab;
    },
    tabChangeRequest(tab) {
      this.currentTab = tab;
    },
    overridePersona(personaName, avatar, error = false) {
      this.$parent.$emit('overridePersona', personaName, avatar, error);
    },
    changeMobileTab(tab, options) {
      this.options = Object.assign({}, this.options, options);
      if(tab === 'ProfileServer' || tab === 'MobileProfileServer') {
        // Component name is the same, so switching does not properly work
        this.currentTab = 'ProfileServerLoading';
        setTimeout(() => {
          this.currentTab = tab;
        }, 1);
      } else this.currentTab = tab;
    },
  },
  mounted() {
    try {
      // Should this fail for whatever reason, fall back
      let element = document.getElementsByClassName('profile-mobile')[0];
      if (window.getComputedStyle(element, null).display === 'block') {
        this.is_mobile = true;
        this.changeMobileTab('MobileProfileOverview');
      }
      else this.is_mobile = false;
    } catch(e) {
      this.is_mobile = null;
    }
    this.getData();
  },
  data() {
    return {
      is_mobile: null,
      AccountStatus: AccountStatus,
      ready: false,
      error: false,
      structure: {},
      currentTab: 'ProfileOverview',
      options: {}
    }
  }
};
</script>

<template>
  <div>
    <!-- Desktop -->
    <div class="profile-desktop">
      <div class="container-fluid" v-if="is_mobile === false">
          <div class="row">
            <div class="col-2">
              <div class="profile-links">
                <div class="profile-link text-muted" :class="{'profile-link-active': currentTab === 'ProfileOverview'}" v-on:click="changeTab('ProfileOverview')">
                  Overview
                </div>
                <div class="profile-link text-muted" :class="{'profile-link-active': currentTab === 'ProfileIdentities'}" v-on:click="changeTab('ProfileIdentities')">
                  Identities
	                <!--
	                <small class="ml-1 badge badge-primary">
		                <i class="fad fa-sparkles"/>
		                NEW
	                </small>
	                -->
                </div>
                <div class="profile-link text-muted" :class="{'profile-link-active': currentTab === 'ProfileBans'}" v-on:click="changeTab('ProfileBans')">
                  Bans
                  <span class="badge badge-danger" v-if="ready && structure.bans.count > 0">
                    <i class="fas fa-engine-warning"></i>
                    {{ structure.bans.count }} public ban(s)
                  </span>
                </div>
                <!--
                <div class="profile-link text-muted" :class="{'profile-link-active': currentTab === 'ProfileMetrics'}" v-on:click="changeTab('ProfileMetrics')">
                  Metrics
                </div>
                -->
	              <div class="profile-link text-muted" :class="{'profile-link-active': currentTab === 'ProfileIntelligence'}" v-on:click="changeTab('ProfileIntelligence')">
		              Intelligence
	              </div>

	              <template v-if="(structure && structure.features && structure.features.user_activity_feed === true)">
		              <div class="profile-link text-muted" :class="{'profile-link-active': currentTab === 'ProfileAudit'}" v-on:click="changeTab('ProfileAudit')">
			              Audit-Trail
			              <small class="ml-1 badge badge-danger text-black">
				              <i class="fad fa-flask"/>
				              TEST
			              </small>
		              </div>
	              </template>

                <template v-if="(structure && structure.servers && structure.servers.length > 0)">
                <div class="profile-link text-muted" :class="{'profile-link-active': currentTab === 'ProfileActivities'}" v-on:click="changeTab('ProfileActivities')">
                  Activities
                </div>
                <hr>
                </template>
                <div
                    class="profile-server-link text-muted m-1"
                    :class="{'profile-link-active': (currentTab === 'ProfileServer' && options.server_id === server.id)}"
                    v-on:click="changeTab('ProfileServer', {server_id: server.id})"
                    v-for="server in orderedServers" :key="server.id"
                >
                  <img :src="'/' +gameIcon(server.game)" alt="server game logo" class="logo-img" height="16px" width="16px">
                  {{ server.identifier }}
                  <span class="badge badge-primary" v-if="server.dataset_available">
                    <i class="fas fa-user" />
                  </span>
                </div>
              </div>
            </div>
            <div class="col profile-component-col">
              <component @overridePersona="overridePersona" @tabChangeRequest="tabChangeRequest" :privacyMode="privacyMode" :options="options" :cftools_id="cftools_id" v-bind:is="currentTab"></component>
            </div>
          </div>
      </div>
    </div>
    <!-- Desktop END -->

    <!-- MOBILE -->
    <div class="profile-mobile">
      <div v-if="is_mobile">
        <div class="mobile-menu">
          <div class="mobile-container" :class="{'active': currentTab === 'MobileProfileOverview'}" v-on:click="changeMobileTab('MobileProfileOverview')">
            Overview
          </div>
          <div class="mobile-container" :class="{'active': currentTab === 'MobileProfileIdentities'}" v-on:click="changeMobileTab('MobileProfileIdentities')">
            Identities
          </div>
	        <div class="mobile-container" :class="{'active': currentTab === 'MobileProfileTraces'}" v-on:click="changeMobileTab('MobileProfileTraces')">
		        Traces
	        </div>
          <div class="mobile-container" :class="{'active': currentTab === 'MobileProfileBans'}" v-on:click="changeMobileTab('MobileProfileBans')">
            Bans
          </div>
	        <div class="mobile-container" :class="{'profile-link-active': currentTab === 'ProfileIntelligence'}" v-on:click="changeTab('ProfileIntelligence')">
		        Intelligence
	        </div>
          <!--
          <div class="mobile-container" :class="{'active': currentTab === 'MobileProfileMetrics'}" v-on:click="changeMobileTab('MobileProfileMetrics')">
            Metrics
          </div>
          -->
          <div class="mobile-container" :class="{'active': currentTab === 'MobileProfileActivities'}" v-on:click="changeMobileTab('MobileProfileActivities')" v-if="structure.servers.length > 0">
            Activities
          </div>
          <div
              class="mobile-server-container"
              v-for="server in structure.servers"
              :key="server.id"
              :class="{'active': (currentTab === 'MobileProfileServer' && options.server_id === server.id)}"
              v-on:click="changeMobileTab('MobileProfileServer', {server_id: server.id})"
          >
            <img :src="'/' +gameIcon(server.game)" alt="server game logo" class="logo-img" height="16px" width="16px">
            {{ server.identifier }}

          </div>
        </div>
        <div class="mobile-content">
          <component @overridePersona="overridePersona" @tabChangeRequest="tabChangeRequest" :privacyMode="privacyMode" :options="options" :cftools_id="cftools_id" v-bind:is="currentTab"></component>
        </div>
      </div>
    </div>

    <div v-if="is_mobile === null && ready">
      <div class="row" style="margin-top: 70px;">
        <div class="col-lg-12">
          <div class="card bg-danger">
            <div class="card-body p-2">
              <h5 class="mt-0 mb-1 text-white">
                <i class="fas fa-ban mr-1"></i>
                {{ $t('profile.errors.viewport.title') }}
              </h5>
              <p class="card-text text-white">
                {{ $t('profile.errors.viewport.message') }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-12 justify-content-center">
          <div  class="info-component text-center align-middle mt-auto mb-auto">
            <div class="info-component-space">
              <half-circle-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
